import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environmentLoader } from '../environments/environmentLoader';
import { ApiService } from './services/gdi-api/api.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'gdi-entreprise';
    siteKey: string;

    constructor(private router: Router, private translate: TranslateService, private apiService: ApiService) {
        translate.setDefaultLang('fr');
        translate.use('fr');

        environmentLoader
            .then(env => {
                this.apiService.apiHost = env.settings.api.host;
                this.apiService.netheosUrl = env.settings.netheos.url;
                this.siteKey = env.settings.recaptcha.siteKey;
            })
            .catch(err => {});
    }

}
